@use 'src/src/app/scss/abstracts' as *;

.confirm-cookies-modal {
  $block-name: &;

  display: flex;
  align-items: center;

  //   &#{$block-name} {
  //   }

  position: fixed;
  z-index: $z-index-confirm-cookies;
  bottom: 100px;
  left: 50%;
  width: 880px;
  max-width: 100%;
  padding: 13px 20px;
  transform: translate3d(-50%, calc(100% + 120px), 0);
  background-color: $color-semantic-secondary;
  border-radius: $border-radius-s;
  transition: transform $transition-fast;

  @include mq($until: lg) {
    right: 15px;
    bottom: 50px;
    left: 15px;
    width: auto;
    transform: translate3d(0, calc(100% + 70px), 0);
  }

  &__text {
    flex: 1 1 auto;
  }

  &__button {
    flex: 0 0 auto;
    margin-left: 30px;
  }

  &--show {
    transform: translate3d(-50%, 0, 0);

    @include mq($until: lg) {
      transform: translate3d(0, 0, 0);
    }
  }

  &--hide {
    transform: translate3d(-50%, calc(100% + 110px), 0);

    @include mq($until: lg) {
      transform: translate3d(0, calc(100% + 60px), 0);
    }
  }
}
